<template>
  <section v-if="user">

    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="col-md-12">
      <h4 class="titulo-prof-amb" >Suas turmas</h4>
      <h6 class="titulo-prof-amb" style="font-size:18px!important;" >Aqui você pode lançar as Presenças, Notas e Conteúdo Aplicado em suas Turmas. Bom trabalho!</h6>
    </div>



    <pm-Card  v-if="acesso.visualizar">
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-12">
            <pm-DataTable :value="item"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
            >

              <pm-Column field="escola" sortable header="ESCOLA" class="text-resp-turm-prof"></pm-Column>
              <pm-Column field="segmento" sortable header="SEGMENTO" class="text-resp-turm-prof"></pm-Column>
              <pm-Column field="serie" sortable header="PERÍODO" class="text-resp-turm-prof">
                <template #body="{data}">
                  <div>
                    {{data.serie}}
                  </div>
                </template>
              </pm-Column>
              <pm-Column field="nome" sortable header="TURMA" class="text-resp-turm-prof"></pm-Column>
              <pm-Column field="id" headerStyle="width: 4rem; text-align: center"
                bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
                <template #body="{data}">
                  <div v-if="data.ativa">
                    <pm-Button v-if="permissaoAcesso.presenca" icon="pi pi-check-circle" title="Presença"
                      class="p-button-raised p-button-rounded  mr-2 btn-semsombra" @click="presenca(data)"
                    />

                    <pm-Button v-if="data.creche == 1 && permissaoAcesso.formulario" icon="pi pi-book" title="Formulário de perguntas dos alunos"
                      class="p-button-success p-button-raised p-button-rounded mr-2 colortuma1 btn-semsombra" @click="formulario(data)"   />

                    <pm-Button v-if="data.creche == 0 && permissaoAcesso.nota" icon="pi pi-book" title="Notas"
                      class="p-button-success p-button-raised p-button-rounded mr-2 colortuma1 btn-semsombra" @click="nota(data)"   />

                    <pm-Button v-if="permissaoAcesso.conteudo" icon="pi pi-list" title="Conteúdo Aplicado" style="color:#fff!important;"
                      class="p-button-warning p-button-raised p-button-rounded mr-2 btn-semsombra" @click="planejamentoProfessor(data)"
                    />
                  </div>
                </template>
              </pm-Column>
            </pm-DataTable>
          </div>
        </div>
      </template>
    </pm-Card>
    <br><br>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Professor } from "@/class/professor";
import { Verificar } from "@/class/verificar.js";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import Util from "@/utils/rota";

export default defineComponent({
  components: {
  },
  data () {
    const columns = [
      /*{ key: 'id' },*/
      {key: 'id_', label:'Visualizar' },
      {key: 'nome', label:'Escola', sortable: true },
      {key: 'logradouro'},
      //{key: 'numero'},
      {key: 'bairro', sortable: true},
      { key: 'id', label:'Ação'},

    ]
    return {
      home: {icon: 'pi pi-home', to: '/ambiente-professor/turmas-professor'},
      items_bread: [
        {label: 'Turmas do Professor'},
      ],
      permissaoAcesso: {
        presenca:0,
        nota:0,
        conteudo:0,
        formulario:0,
      },
      item: [],
      columns,
      perPage: 5,
      currentPage: 1,
      user:"",
      value:[],
      exibirCalendario:true,
      calendarioAll: [],
      dataSelecionada:'',
      planejamento_pedagogico: [],
      professor:[],
      acesso:{
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      }
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.item.length / this.perPage)
          : this.item.length
    },

    setURL(){
			const a = this.menu_items.map((v, i)=>{
				return {...v, icon: `url(${v.icon})`, delay: `${i / this.delayAnimation}s`};
			});
			return a;
		}
  },

  methods:{
      async  verificarPermissao() {
        const util = new Util();
        const token = sessionStorage.getItem("token");

        const data = await util.WhoIam(token);
        if (data.status == 1) {
          //verificar se ele tem autorização para usar presenca, nota ou conteudo dado
          for (const el of data.permissao) {
            if (el.permissao_modulo_id == 11)
              {this.permissaoAcesso.presenca = 1;}
            if (el.permissao_modulo_id == 13)
              {this.permissaoAcesso.nota = 1;}
            if (el.permissao_modulo_id == 15)
              {this.permissaoAcesso.conteudo = 1;}
            if (el.permissao_modulo_id == 17)
              {this.permissaoAcesso.formulario = 1;}
          }
        }
      },
      async presenca(a){
        try {
          sessionStorage.setItem("Professor_id", a.professor_id);
          sessionStorage.setItem("Professor_turma_id", a.id);
          sessionStorage.setItem("Professor_professor_id", this.professor.id);
          sessionStorage.setItem("Professor_turma_disciplina_id", a.turma_disciplina_id);
          sessionStorage.setItem("Professor_segmento", a.segmento_id);
          sessionStorage.setItem("Professor_escola_id", a.escola_id);
          sessionStorage.setItem("Professor_serie_id", a.serie_id);
          sessionStorage.setItem("Professor_turno", a.turno);
          sessionStorage.setItem("Professor_dataSelecionada", this.dataSelecionada);

          this.$router.push({ name: "presencaProfessor", params: { id: a.id, turma_id: a.id, professor_id: this.professor.id, turma_disciplina_id: a.turma_disciplina_id, segmento: a.segmento_id, dataSelecionada:this.dataSelecionada, escola_id: a.escola_id, serie_id: a.serie_id, turno: a.turno } });
        } catch (e) {
        }
      },
      async nota(a){
        try {
          sessionStorage.setItem("Professor_id", a.professor_id);
          sessionStorage.setItem("Professor_turma_id", a.id);
          sessionStorage.setItem("Professor_professor_id", this.professor.id);
          sessionStorage.setItem("Professor_turma_disciplina_id", a.turma_disciplina_id);
          sessionStorage.setItem("Professor_segmento", a.segmento_id);
          sessionStorage.setItem("Professor_escola_id", a.escola_id);
          sessionStorage.setItem("Professor_serie_id", a.serie_id);
          sessionStorage.setItem("Professor_turno", a.turno);
          this.$router.push({ name: "notasProfessor", params: { id: a.id, turma_id: a.id, professor_id: this.professor.id, turma_disciplina_id: a.turma_disciplina_id, segmento: a.segmento_id, escola_id: a.escola_id, serie_id: a.serie_id, turno: a.turno } });
        } catch (e) {
        }
      },
      formulario(a) {
        sessionStorage.setItem("Professor_id", a.professor_id);
        sessionStorage.setItem("Professor_turma_id", a.id);
        sessionStorage.setItem("Professor_professor_id", this.professor.id);
        sessionStorage.setItem("Professor_turma_disciplina_id", a.turma_disciplina_id);
        sessionStorage.setItem("Professor_segmento", a.segmento_id);
        sessionStorage.setItem("Professor_escola_id", a.escola_id);
        sessionStorage.setItem("Professor_serie_id", a.serie_id);
        sessionStorage.setItem("Professor_turno", a.turno);
        this.$router.push({ name: "crecheFormulario" , params: { id: a.id, turma_id: a.id, professor_id: this.professor.id, turma_disciplina_id: a.turma_disciplina_id, segmento: a.segmento_id, escola_id: a.escola_id, serie_id: a.serie_id, turno: a.turno}});
      },
      async planejamentoProfessor(a){
        sessionStorage.setItem("Professor_id", a.professor_id);
        sessionStorage.setItem("Professor_turma_id", a.id);
        sessionStorage.setItem("Professor_professor_id", this.professor.id);
        sessionStorage.setItem("Professor_turma_disciplina_id", a.turma_disciplina_id);
        sessionStorage.setItem("Professor_segmento", a.segmento_id);
        sessionStorage.setItem("Professor_escola_id", a.escola_id);
        sessionStorage.setItem("Professor_serie_id", a.serie_id);
        sessionStorage.setItem("Professor_turno", a.turno);
        this.$router.push({ name: "planejamentoProfessor", params: { id: a.id, turma_id: a.id, professor_id: this.professor.id, turma_disciplina_id: a.turma_disciplina_id, segmento: a.segmento_id, escola_id: a.escola_id, serie_id: a.serie_id, turno: a.turno } });
      },
      async listarTudoCalendario() {
          try {
            const data = await Calendario.calendarioAtual();
            this.calendarioAll = data.data;
          } catch (e) {
            console.log(e);
          }
      },
      async listarTudo() {
          try {
            const token = sessionStorage.getItem("token");
            const ano = sessionStorage.getItem("anoSelecionado");

            const whoiam = await LoginInfo.WhoIam(token);
            // const calendario = await Calendario.calendarioAtual();

            if(whoiam.data.funcao != 5){
              this.$router.push({ name: "login"});
            }


            this.user = whoiam.data.usuario.nome;
            this.professor = whoiam.data.usuario;

            const data = await Professor.obtemTodasTurmasProfessorOrganizadoTabela(whoiam.data.usuario.id, ano);
            this.item = data.data;

          } catch (e) {
            console.log(e);
          }
      },
  },
  async beforeMount() {
    this.value[0] = true;
    await this.listarTudoCalendario();
    await this.listarTudo();
    await this.verificarPermissao();
    const acesso = await Verificar.AcessoLocal (16);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  }

})


</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }
</style>



<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
  $font: 'Josefin Sans', sans-serif;
  $accent: #ffcc80;

  .title{
    color: $accent;
  }
  .af{
    display: block;
    font-size: 1.1em;
    position: fixed;
    bottom: 1em;
    right: 1em;
    &__auth{
      display: block;
      margin-top: 0.5em;
      font-size: 0.9em;
    }
  }
  .circle-navigation{
    position: absolute;
    /*top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
    z-index: 1;
    &__nav-btn{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: none;
      transition: .3s ease;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      font-family: $font;
      background-color: #ffe0b2;
      z-index: 15;
      outline: none;
      box-shadow: 1px 2px 10px #ffb74d;
      &:hover{
        background: $accent;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:active{
        background: #ff9800;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 1px 2px 1px #ffb74d;
      }
    }
    &__list{
      list-style-type: none;
      padding: 0;
      margin: 0;
      //position: absolute;
      top: 4px;
      left: 6px;
      z-index: -1;
    }
    &__item{
      z-index: -1;
      position: absolute;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      transition-duration: .2s;
      border-radius: 50%;
      background-color: $accent;
      top: 7px;
    }
    &__link{
      color: #000;
      text-decoration: none;
      display:block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;

      text-align: center;
      padding-top: 22px;
      &:hover{
        background-repeat: no-repeat!important;
        background-position: center!important;
        transition: .3s ease;
        background: $accent;
      }
    }
  }
  .icon-position{
    background-repeat: no-repeat;
    background-position: center;
  }

  .titulo-prof-amb {
    margin-top: 25px;
    margin-bottom: 25px;
    color: #212121;
    font-size: 25px;
    font-weight: 600;
  }
</style>
